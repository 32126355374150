<!--
--------------------------------------------------------------------------------
<copyright file="FactoryOverview.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.fact_overview') }}</summary>
      <span>{{ $t('widget_info_text.fact_overview_txt') }}</span>
      <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
      <p class="text info-bkg">{{ $t('widget_info_text.fact_overview_info') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.fact_overview_tip') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.fact_overview_tip2') }}</li>
      </ul>
      <details>
        <summary>{{ $t('widget_info_text.fact_overview_status_info') }}</summary>
        <span>{{ $t('widget_info_text.fact_overview_status_info_txt') }}</span>
        <ul class="list">
          <li class="list-item">{{ $t('widget_info_text.fact_overview_maq_types') }}</li>
          <li class="list-item">
            {{ $t('widget_info_text.fact_overview_maq_names') }}
            {{ $t('widget_info_text.color_code') }}:
            <ul class="list">
              <li class="list-item2">
                <span class="work">{{ $t('widget_info_text.green') }}</span>
                {{ $t('widget_info_text.fact_overview_green_txt') }}
              </li>
              <li class="list-item2">
                <span class="wait">{{ $t('widget_info_text.brown') }}</span>
                {{ $t('widget_info_text.fact_overview_brown_txt') }}
              </li>
              <li class="list-item2">
                <span class="offline">{{ $t('widget_info_text.dark_gray') }}</span
                >, {{ $t('widget_info_text.fact_overview_dgray_txt') }}
              </li>
              <li class="list-item2">
                <span class="error">{{ $t('widget_info_text.red') }}</span
                >, {{ $t('widget_info_text.fact_overview_red_txt') }}
              </li>
              <li class="list-item2">
                <span class="idle">{{ $t('widget_info_text.light_orange') }}</span
                >, {{ $t('widget_info_text.fact_overview_lOrange_txt') }}
              </li>
              <li class="list-item2">
                <span class="stop">{{ $t('widget_info_text.dark_orange') }}</span
                >, {{ $t('widget_info_text.fact_overview_dOrange_txt') }}
              </li>
            </ul>
          </li>
          <li class="list-item">{{ $t('widget_info_text.fact_overview_lbl_indicators') }}</li>
          <li class="list-item">
            {{ $t('widget_info_text.fact_overview_val_txt1') }} <br />
            {{ $t('widget_info_text.fact_overview_val_txt2') }}
            <p class="demi-bold">{{ $t('widget_info_text.color_code') }}:</p>
            <ul class="list">
              <li class="list-item2">
                <span class="work">{{ $t('widget_info_text.green') }}</span
                >, {{ $t('widget_info_text.fact_overview_val_cond1') }}
              </li>
              <li class="list-item2">
                <span class="idle">{{ $t('widget_info_text.orange') }}</span
                >, {{ $t('widget_info_text.fact_overview_val_cond2') }}
              </li>
              <li class="list-item2">
                <span class="error">{{ $t('widget_info_text.red') }}</span
                >, {{ $t('widget_info_text.fact_overview_val_cond3') }}
              </li>
            </ul>
          </li>
        </ul>
      </details>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class FactoryOverviewWidgetInfo extends Vue {}
</script>
